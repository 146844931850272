<template>
  <v-app>
    <router-view>
    </router-view>
    <v-snackbar v-model="toast.show" :timeout="toast.timeout" :color="toast.color" bottom>
      {{ toast.message }}
      <v-btn v-if="toast.timeout === 0" color="white" text @click="toast.show = false">Close</v-btn>
    </v-snackbar>
  </v-app>
</template>

<script>
import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState('app', ['toast'])
  }
}
</script>

<style lang="scss">
    @import "@/scss/overrides.scss";
</style>
