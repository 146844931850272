import { apiClient, makeServicePlugin, BaseModel } from "@/store/api";

class Notification extends BaseModel {
    constructor(data, options) {
        super(data, options);
    }

    // Required for $FeathersVuex plugin to work after production transpile.
    static modelName = "Notification";

    // Define default properties here
    static instanceDefaults() {
        return {
            id: null,
            user_id: '',
            manager_id: '',
            title: '',
            description: '',
            type: '',
            createdAt: ''
        };
    }
}
const servicePath = "notifications";
const servicePlugin = makeServicePlugin({
    Model: Notification,
    service: apiClient.service(servicePath),
    servicePath
});

// Setup the client-side Feathers hooks.
apiClient.service(servicePath).hooks({
    before: {
        all: [],
        find: [],
        get: [],
        create: [],
        update: [],
        patch: [],
        remove: []
    },
    after: {
        all: [],
        find: [],
        get: [],
        create: [],
        update: [],
        patch: [],
        remove: []
    },
    error: {
        all: [],
        find: [],
        get: [],
        create: [],
        update: [],
        patch: [],
        remove: []
    }
});

export default servicePlugin;
