import actions from './actions'
import mutations from './mutations'

// state initial values
const state = {

  // App.vue main toast
  toast: {
    show: false,
    color: '',
    message: '',
    timeout: 3000
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}
