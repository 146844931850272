const showToast = ({ state, commit }, message) => {
  if (state.toast.show) commit('hideToast')

  setTimeout(() => {
    commit('showToast', {
      color: '#6F70FF',
      message,
      timeout: 3000
    })
  })
}

const showError = ({ state, commit }, message) => {
  if (state.toast.show) commit('hideToast')

  setTimeout(() => {
    commit('showToast', {
      color: '#f5365c',
      message: message,
      timeout: 3000
    })
  })
}

const showSuccess = ({ state, commit }, message) => {
  if (state.toast.show) commit('hideToast')

  setTimeout(() => {
    commit('showToast', {
      color: '#2dce89',
      message,
      timeout: 3000
    })
  })
}

export default {
  showToast,
  showError,
  showSuccess
}
