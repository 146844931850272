<template>
  <div>
    <template v-if="auth">
      <v-footer color="transparent" class="mx-4">
        <v-card class="flex" color="transparent">
          <v-card-text class="px-0">
            <v-row>
                <v-col cols="12" class="d-flex justify-space-between">
                    <div class="copyright text-body-2 ls-0 text-muted">
                        © {{ new Date().getFullYear() }}
                        <span class="text-primary text-body-2 ls-0">GoDigy</span>
                    </div>
                    <div>
                        <a
                            href="#"
                            class="text-decoration-none text-muted text-primary text-body-2 ls-0"
                            target="_blank"
                            >Licence
                        </a>
                    </div>
                </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-footer>
    </template>
  </div>
</template>
<script>
export default {
  name: "content-footer",
  props: {
    auth: Boolean
  },
  data() {
    return {
      footer: [
        {
          linkName: "License",
          link: ""
        }
      ]
    };
  }
};
</script>
