<template>
    <!-- <v-app>
        <v-main class="dashboard-main-wrapper">
            <app-bar class="dashboard-appbar-top" background="header-white" has-bg @drawer-toggle="drawer = $event" :toggle-active="drawer"></app-bar>
            <fade-transition :duration="200" origin="center top" mode="out-in">
                
                <router-view></router-view>
            </fade-transition>
            <content-footer v-if="!$route.meta.hideFooter"></content-footer>
        </v-main>
    </v-app> -->

    <MasterDashboardLayout>
        <template v-slot:header>
            <app-bar class="dashboard-appbar-top" background="header-white" has-bg @drawer-toggle="drawer = $event" :toggle-active="drawer"></app-bar>
        </template>
        <template v-slot:content>
            <fade-transition :duration="200" origin="center top" mode="out-in">
                <router-view></router-view>
            </fade-transition>
        </template>
    </MasterDashboardLayout>
</template>
<script>
// /* eslint-disable no-new */
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";
import MasterDashboardLayout from "@/components/MasterDashboardLayout";

function hasElement(className) {
    return document.getElementsByClassName(className).length > 0;
}

function initScrollbar(className) {
    if (hasElement(className)) {
        new PerfectScrollbar(`.${className}`);
    } else {
        // try to init it later in case this component is loaded async
        setTimeout(() => {
            initScrollbar(className);
        }, 100);
    }
}

// import DashboardNavbar from './DashboardNavbar.vue';
import { FadeTransition } from "vue2-transitions";
import AppBar from "@/components/AppBar.vue";
import ContentFooter from "@/components/Footer.vue";

export default {
    components: {
        ContentFooter,
        FadeTransition,
        AppBar,
        MasterDashboardLayout
    },
    data() {
        return {
            drawer: null
        };
    },
    methods: {
        initScrollbar() {
            let isWindows = navigator.platform.startsWith("Win");
            if (isWindows) {
                initScrollbar("sidenav");
            }
        }
    },
    mounted() {
        this.initScrollbar();
    }
};
</script>
<style lang="scss" scoped>
.dashboard-appbar-top {
    background-color: white;
}
</style>
