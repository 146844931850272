<template>
  <v-app-bar
    absolute
    :color="background"
    height="auto"
    class="pa-2 toolbar-content-padding-y-none authHeader"
    flat
  >
    <v-container :fluid="true" class="mx-0">
      <v-row>
        <v-col cols="6" class="d-flex align-center xs-title">
          <router-link
            to="login"
            class="text-decoration-none text-black text-h2 "
            ><h2>GoDigy</h2>
          </router-link>
        </v-col>
        <v-col cols="6" class="d-flex align-center justify-end">
          <span class="version-number">Version {{appVersion}}</span>
        </v-col>
      </v-row>
    </v-container>
  </v-app-bar>
</template>
<script>
export default {
  name: "app-bar",
  props: {
    background: String
  },
  data() {
    return {
    };
  },
  computed: {
    appVersion: () => {
      return process.env.VUE_APP_VERSION || 'N/A';
    }
  }
};
</script>

<style lang="scss" scoped>
@media screen and (max-width: 600px) {
    .xs-title {
        padding-left: 0;
    }
}
.version-number {
  font-size: 14px;
  opacity: 0.5;
}
</style>
