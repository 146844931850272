import { apiClient, makeServicePlugin, BaseModel } from "@/store/api";

class Form extends BaseModel {
    constructor(data, options) {
        super(data, options);
    }

    // Required for $FeathersVuex plugin to work after production transpile.
    static modelName = "Form";

    // Define default properties here
    static instanceDefaults() {
        return {
            id: null,
            manager_id: null,
            assignees: [],
            name: '',
            status: '',
            schedule: {
                type: '',
                releaseDate: ''
            },
            sections: [],
            hide: false,
            disabled: false,
            updatedAt: ''
        };
    }
}
const servicePath = "forms";
const servicePlugin = makeServicePlugin({
    Model: Form,
    service: apiClient.service(servicePath),
    servicePath
});

// Setup the client-side Feathers hooks.
apiClient.service(servicePath).hooks({
    before: {
        all: [],
        find: [],
        get: [],
        create: [],
        update: [],
        patch: [],
        remove: []
    },
    after: {
        all: [],
        find: [],
        get: [],
        create: [],
        update: [],
        patch: [],
        remove: []
    },
    error: {
        all: [],
        find: [],
        get: [],
        create: [],
        update: [],
        patch: [],
        remove: []
    }
});

export default servicePlugin;
