import { apiClient, makeServicePlugin, BaseModel } from "@/store/api";

class QRSingleReportingModel extends BaseModel {
    constructor(data, options) {
        super(data, options);
    }

    // Required for $FeathersVuex plugin to work after production transpile.
    static modelName = "QRSingleReporting";

    // Define default properties here
    static instanceDefaults() {
        return {
            context: ""
        };
    }
}

const servicePluginQRSingleReporting = makeServicePlugin({
    Model: QRSingleReportingModel,
    service: apiClient.service('reporting/qr/single'),
    servicePath: 'reporting/qr/single',
    namespace: 'reporting-qr-single'
});

export default servicePluginQRSingleReporting;

