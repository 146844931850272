import { apiClient, makeServicePlugin, BaseModel } from "@/store/api";

class Overdue extends BaseModel {
    constructor(data, options) {
        super(data, options);
    }

    // Required for $FeathersVuex plugin to work after production transpile.
    static modelName = "Overdue";

    // Define default properties here
    static instanceDefaults() {
        return {

        };
    }
}
const servicePath = "overdue";
const servicePlugin = makeServicePlugin({
    Model: Overdue,
    service: apiClient.service(servicePath),
    servicePath,
});

// Setup the client-side Feathers hooks.
apiClient.service(servicePath).hooks({
    before: {
        all: [],
        find: [],
        get: [],
        create: [],
        update: [],
        patch: [],
        remove: [],
    },
    after: {
        all: [],
        find: [],
        get: [],
        create: [],
        update: [],
        patch: [],
        remove: [],
    },
    error: {
        all: [],
        find: [],
        get: [],
        create: [],
        update: [],
        patch: [],
        remove: [],
    },
});

export default servicePlugin;
