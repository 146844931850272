import { apiClient, makeServicePlugin, BaseModel } from "@/store/api";

class QRBulkReportingModel extends BaseModel {
    constructor(data, options) {
        super(data, options);
    }

    // Required for $FeathersVuex plugin to work after production transpile.
    static modelName = "QRBulkReporting";

    // Define default properties here
    static instanceDefaults() {
        return {
            context: ""
        };
    }
}

const servicePluginQRBulkReporting = makeServicePlugin({
    Model: QRBulkReportingModel,
    service: apiClient.service('reporting/qr/bulk'),
    servicePath: 'reporting/qr/bulk',
    namespace: 'reporting-qr-bulk'
});

export default servicePluginQRBulkReporting;

