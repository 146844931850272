import { apiClient, makeServicePlugin, BaseModel } from "@/store/api";

class ResultsReportingModel extends BaseModel {
    constructor(data, options) {
        super(data, options);
    }

    // Required for $FeathersVuex plugin to work after production transpile.
    static modelName = "ResultsReporting";

    // Define default properties here
    static instanceDefaults() {
        return {
            context: ""
        };
    }
}

const servicePluginResultsReporting = makeServicePlugin({
    Model: ResultsReportingModel,
    service: apiClient.service('reporting/results'),
    servicePath: 'reporting/results',
    namespace: 'reporting-results'
});

export default servicePluginResultsReporting;

