<template>
    <v-app-bar
        v-if="!isMobile"
        :color="background"
        rounded
        height="auto"
        class="py-4 px-2 mb-6 ma-4 toolbar-content-padding-y-none card-bg card-shadow"
        :class="{ 'border-bottom': !hasBg, 'border-bottom-dark': hasBg }">
        <v-row class="align-center justify-space-between">
            <v-col cols="auto" sm="auto" class="logo-section">
                <h2>GoDigy</h2>
                <span class="app-version">{{appVersion}}</span>
            </v-col>

            <v-col cols="auto" sm="auto" class="d-flex align-center">
                <router-link v-for="(item, index) in navListFilter" :key="index" class="header-link" :class="{ 'btn-dark-hover': !hasBg, 'btn-hover': hasBg }" :to="item.link">
                    <feather :type="item.icon" class="" stroke-width="1.5"></feather>
                    <span class="text-subtitle-2" :class="{ 'btn-dark-hover': !hasBg, 'btn-hover': hasBg }" :style="!hasBg ? 'color: rgba(0,0,0, .6)' : ''">{{ item.name }}</span>
                </router-link>
            </v-col>

            <v-col cols="auto" sm="auto" class="text-right">
                <v-menu :close-on-content-click="false" transition="slide-y-transition" offset-y :position-x="50">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn icon :class="{ 'btn-dark-hover': !hasBg, 'btn-hover': hasBg }" v-bind="attrs" v-on="on">
                            <v-avatar size="33" color="#F3F4FD">
                                <feather type="bell" class="text-darker" stroke-width="1.5"></feather>
                            </v-avatar>
                        </v-btn>
                    </template>

                    <v-list v-if="notifications" min-width="480" max-width="480" class="pt-0">
                        <div class="card-title-wrapper pa-8">
                            <v-row>
                                <v-col cols="8" class="d-flex align-center py-0">
                                    <v-card-title class="text-h2 font-weight-600 pa-0 mb-0 text-center">Activity Timeline</v-card-title>
                                </v-col>
                            </v-row>
                        </div>
                        <vue-perfect-scrollbar v-if="notifications.length" :settings="perfectScrollbarSettings" class="ps-customizer-area">
                            <v-timeline dense align-top class="timeline-line-color pr-8">
                                <v-timeline-item v-for="(notification, i) in notifications" :key="i" small class="timeline pb-8">
                                    <template v-slot:icon>
                                        <v-avatar size="33" color="#F3F4FD">
                                            <feather type="bell" class="text-darker" stroke-width="1.5"></feather>
                                        </v-avatar>
                                    </template>

                                    <v-card>
                                        <v-card-title class="px-0 pt-1 pb-1">
                                            <span class="text-muted text-caption ls-0 font-weight-600">{{ createdAt(notification.createdAt) }}</span>
                                        </v-card-title>
                                        <v-card-text class="px-0">
                                            <h5 class="text-h4 text-typo font-weight-600 mt-1 mb-0">
                                                {{ notification.title }}
                                            </h5>
                                            <p class="mt-1 mb-0 text-body font-weight-thin">{{ notification.description }}</p>
                                        </v-card-text>
                                        <div class="avatar-with-name">
                                            <v-avatar size="33" color="#F3F4FD">
                                                <span v-if="!retrieveUser(notification.user_id).avatar" class="text-primary text-h5 font-weight-600">{{
                                                    userInitials(retrieveUser(notification.user_id))
                                                }}</span>
                                                <img v-else :src="`${$feathersConnectionString}/${retrieveUser(notification.user_id).avatar.url}`" alt="User" />
                                            </v-avatar>
                                            <p class="text-h5 pa-0 ma-0 ml-3">
                                                {{ retrieveUser(notification.user_id).first_name }}
                                                {{ retrieveUser(notification.user_id).last_name }}
                                            </p>
                                        </div>
                                    </v-card>
                                </v-timeline-item>
                            </v-timeline>
                            <!-- <pre>{{ notifications }}</pre> -->
                        </vue-perfect-scrollbar>
                        <v-card v-else>
                            <v-card-title class="ml-4">
                                <span class="text-muted text-caption ls-0 font-weight-600">You currently have 0 notifications.</span>
                            </v-card-title>
                        </v-card>
                    </v-list>
                </v-menu>

                <v-menu transition="slide-y-transition" offset-y :position-x="50">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn icon :class="{ 'btn-dark-hover': !hasBg, 'btn-hover': hasBg }" :color="linkColor" v-bind="attrs" v-on="on">
                            <v-avatar size="33" color="#F3F4FD">
                                <feather type="user" class="text-darker" stroke-width="1.5"></feather>
                            </v-avatar>
                        </v-btn>
                    </template>

                    <v-list min-width="192" max-width="192" nav three-line>
                        <v-list-item link v-for="(item, i) in accountMenuFilter" :key="i" class="min-height-auto" :class="{ 'border-bottom': i == accountMenu.length - 1 }" :to="item.link">
                            <v-list-item-avatar class="my-0 me-5" width="20" min-width="15" height="37">
                                <feather :type="item.icon" class="text-darker" size="1.2rem" stroke-width="1.5"></feather>
                            </v-list-item-avatar>

                            <v-list-item-content class="pa-0">
                                <v-list-item-title class="text-darker">{{ item.name }}</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <v-divider></v-divider>
                        <v-list-item link class="min-height-auto" @click="logout">
                            <v-list-item-avatar class="my-0 me-5" width="20" min-width="15" height="37">
                                <feather type="log-out" class="text-darker" size="1.2rem" stroke-width="1.5"></feather>
                            </v-list-item-avatar>
                            <v-list-item-content>
                                <v-list-item-title class="text-darker">Logout</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </v-col>
        </v-row>
    </v-app-bar>
    <v-app-bar v-else :color="background" rounded height="auto" class="py-4 px-2 mb-6 ma-4 toolbar-content-padding-y-none card-bg card-shadow">
        <div class="d-flex flex-grow-1 align-center justify-space-between">
            <h2>GoDigy</h2>
            <div>
                <v-btn to="/qr-code" icon>
                    <v-avatar size="33" color="#F3F4FD">
                        <v-img :src="qrIcon" max-height="15" contain />
                    </v-avatar>
                </v-btn>
                <v-btn to="/my-forms" icon>
                    <v-avatar size="33" color="#F3F4FD">
                        <feather type="file-text" class="text-darker" stroke-width="1.5"></feather>
                    </v-avatar>
                </v-btn>
                <v-menu transition="slide-y-transition" offset-y :position-x="50">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn icon :class="{ 'btn-dark-hover': !hasBg, 'btn-hover': hasBg }" :color="linkColor" v-bind="attrs" v-on="on">
                            <v-avatar size="33" color="#F3F4FD">
                                <feather type="user" class="text-darker" stroke-width="1.5"></feather>
                            </v-avatar>
                        </v-btn>
                    </template>
                    <v-list min-width="192" max-width="192" nav three-line>
                        <v-list-item link v-for="(item, i) in accountMobileMenuFilter" :key="i" class="min-height-auto" :class="{ 'border-bottom': i == accountMenu.length - 1 }" :to="item.link">
                            <v-list-item-avatar class="my-0 me-5" width="20" min-width="15" height="37">
                                <feather :type="item.icon" class="text-darker" size="1.2rem" stroke-width="1.5"></feather>
                            </v-list-item-avatar>

                            <v-list-item-content class="pa-0">
                                <v-list-item-title class="text-darker">{{ item.name }}</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <v-divider></v-divider>
                        <v-list-item link class="min-height-auto" @click="logout">
                            <v-list-item-avatar class="my-0 me-5" width="20" min-width="15" height="37">
                                <feather type="log-out" class="text-darker" size="1.2rem" stroke-width="1.5"></feather>
                            </v-list-item-avatar>
                            <v-list-item-content>
                                <v-list-item-title class="text-darker">Logout</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </div>
        </div>
    </v-app-bar>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import VuePerfectScrollbar from 'vue-perfect-scrollbar';
import { makeFindMixin } from 'feathers-vuex';
import isMobile from '../plugins/isMobile';

export default {
    name: 'app-bar',
    components: {
        VuePerfectScrollbar
    },
    props: {
        background: String,
        hasBg: Boolean,
        linkColor: String,
        toggleActive: String
    },
    data() {
        return {
            isMobile: isMobile(),
            perfectScrollbarSettings: {
                maxScrollbarLength: 200,
                wheelPropagation: false
            },
            displayQR: false,
            drawer: false,
            togglerActive: false,
            qrIcon: require('@/assets/img/illustrations/qrcode-solid.svg'),
            navList: [
                {
                    name: 'Dashboard',
                    icon: 'home',
                    link: '/dashboard/overview',
                    validRoles: ['Admin', 'Manager', 'Employee', 'GlobalManager']
                },
                {
                    name: 'Form Manager',
                    icon: 'edit',
                    link: '/form-manager',
                    validRoles: ['Admin', 'Manager', 'GlobalManager']
                },
                {
                    name: 'My Forms',
                    icon: 'file-text',
                    link: '/my-forms',
                    validRoles: ['Admin', 'Manager', 'Employee', 'GlobalManager']
                }
            ],
            accountMenu: [
                {
                    name: 'My Profile',
                    icon: 'user',
                    link: '/profile',
                    validRoles: ['Admin', 'Manager', 'Employee', 'GlobalManager']
                },
                {
                    name: 'Notifications',
                    icon: 'bell',
                    link: '/notifications',
                    validRoles: ['Admin', 'Manager', 'Employee', 'GlobalManager']
                },
                {
                    name: 'Users',
                    icon: 'users',
                    link: '/users',
                    validRoles: ['Admin', 'Manager', 'GlobalManager']
                },
                {
                    name: 'Organisation',
                    icon: 'briefcase',
                    link: '/organisation',
                    validRoles: ['Admin', 'Manager', 'GlobalManager']
                },
                {
                    name: 'Licence',
                    icon: 'credit-card',
                    link: '/licence',
                    validRoles: ['Admin']
                }
            ],
            accountMobileMenu: [
                {
                    name: 'My Profile',
                    icon: 'user',
                    link: '/profile',
                    validRoles: ['Admin', 'Manager', 'Employee', 'GlobalManager']
                }
            ]
        };
    },
    mixins: [makeFindMixin({ service: 'notifications', watch: true }), makeFindMixin({ service: 'users', watch: true })],
    methods: {
        async logout() {
            try {
                await this.authLogout();
                this.showSuccess('Successfully Logged out!');

                this.$router.replace({
                    name: 'login'
                });
            } catch (error) {
                this.showError(error);
            }
        },
        retrieveUser(userId) {
            const foundUser = this.users.find((user) => user.id === userId);

            if (foundUser === undefined) {
                return false;
            }

            return foundUser;
        },
        userInitials(user) {
            const initials = (user.first_name + ' ' + user.last_name).match(/\b\w/g) || [];

            return ((initials.shift() || '') + (initials.pop() || '')).toUpperCase();
        },
        createdAt(time) {
            var d = new Date(time);
            return this.$moment(d).calendar();
        },
        drawerClose() {
            this.togglerActive = !this.togglerActive;
            this.$emit('drawer-toggle', true);
        },
        ...mapActions('apiAuth', { authLogout: 'logout' }),
        ...mapActions('app', ['showSuccess', 'showError'])
    },
    computed: {
        appVersion: () => {
          return process.env.VUE_APP_VERSION || 'N/A';
        },
        notificationsParams() {
            if (!this.authUser?.role) return;

            if (this.authUser.role === 'Admin' || this.authUser.role === 'GlobalManager') {
                return {
                    query: {
                        $sort: {
                            createdAt: -1
                        }
                    }
                };
            }

            if (this.authUser.role === 'Manager') {
                return {
                    query: {
                        manager_id: this.authUser.id,
                        $sort: {
                            createdAt: -1
                        }
                    }
                };
            } else {
                return {
                    query: {
                        user_id: this.authUser.id,
                        $sort: {
                            createdAt: -1
                        }
                    }
                };
            }
        },
        usersParams() {
            return {
                query: {}
            };
        },
        accountMenuFilter() {
            if (this.authUser) {
                return this.accountMenu.filter((item) => {
                    return item.validRoles.includes(this.authUser.role);
                });
            }

            return this.accountMenu;
        },
        accountMobileMenuFilter() {
            if (this.authUser) {
                return this.accountMobileMenu.filter((item) => {
                    return item.validRoles.includes(this.authUser.role);
                });
            }

            return this.accountMobileMenu;
        },
        navListFilter() {
            if (this.authUser) {
                return this.navList.filter((item) => {
                    return item.validRoles.includes(this.authUser.role);
                });
            }

            return this.navList;
        },
        ...mapGetters('apiAuth', { authUser: 'user' })
    },
    watch: {
        toggleActive(val) {
            this.togglerActive = val;
        }
    }
};
</script>

<style lang="scss" scoped>
.logo-section {
    position: relative;
  h2 {
    margin-bottom: 12px;
  }
  .app-version {
    position: absolute;
    font-size: 12px;
    opacity: 0.5;
    bottom: 10px;
    left: 12px;
  }
}
.ps-customizer-area {
    max-height: 30rem;
}

.header-link {
    color: transparentize($color: #000, $amount: 0.13) !important;
    text-decoration: none;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    transition: color 150ms ease-out;
    &:hover,
    &:focus,
    &:active {
        color: transparentize($color: #6f70ff, $amount: 0) !important;
        .btn-hover {
            color: transparentize($color: #6f70ff, $amount: 0) !important;
        }
    }
    .btn-hover {
        margin-left: 8px;
    }
    &:not(:last-child) {
        margin-right: 32px;
    }

    &.router-link-active {
        color: transparentize($color: #6f70ff, $amount: 0) !important;
    }
}
.avatar-with-name {
    display: flex;
    align-items: center;
    strong {
        margin-left: 8px;
        font-size: 14px;
    }
}
.v-timeline::before {
    background-image: linear-gradient(0deg, #e9ecef, #e9ecef 50%, transparent 50%, transparent 100%) !important;
    background-size: 2px 20px !important;
    background-color: white !important;
    width: 2px !important;
    height: auto !important;
    left: calc(48px - 1px) !important;
    top: 20px !important;
    bottom: 0 !important;
}

.border-top {
    border-top: 1px solid #e9ecef !important;
}
</style>
