import Vue from 'vue'
import Vuex from 'vuex'

// API and auth
import { apiAuth } from './auth'
import { FeathersVuex } from './api'

import AppModule from './app'

Vue.use(Vuex)
Vue.use(FeathersVuex)


const requireModule = require.context(
  // The path where the service modules live
  './api/services',
  // Whether to look in subfolders
  false,
  // Only include .js files (prevents duplicate imports`)
  /\.js$/
)

// Feathers services plug-in
const servicePlugins = requireModule
  .keys()
  .map((modulePath) => requireModule(modulePath).default)

/**
 * Main Vuex Store
 */
const store = new Vuex.Store({
  modules: {
    app: AppModule
  },
  plugins: [...servicePlugins, apiAuth]
})

export default store
