<template>
    <v-app>
        <v-main>
            <div class="master-dashboard-layout">
                <div class="header-row">
                    <slot name="header"></slot>
                </div>
                <div class="content-row ma-4 mt-0">
                    <div class="content">
                        <slot name="content"></slot>
                    </div>
                </div>
            </div>
        </v-main>
    </v-app>
</template>

<script>
export default {
    name: "MasterDashboardLayout"
};
</script>

<style lang="scss" scoped>
.master-dashboard-layout {
    height: 100vh;
    max-height: 100vh;
    display: flex;
    flex-flow: column nowrap;

    .content-row {
        display: flex;
        flex-flow: row nowrap;
        flex-grow: 1;
        max-height: 100%;
        .sidebar {
            align-self: stretch;
        }
        .content {
            flex-grow: 1;
        }
    }
    
    .header-row {
        flex-grow: 0;
    }
}
</style>
