<template>
    <v-app>
        <v-main class="auth-pages">
            <div class="auth-container">
                <app-bar-auth background="transparent"></app-bar-auth>
                <fade-transition :duration="200" origin="center top" mode="out-in">
                    <v-container class="pb-0 my-16">
                        <router-view></router-view>
                    </v-container>
                </fade-transition>
                <content-footer class="authFooter" auth v-if="!$route.meta.hideFooter"></content-footer>
            </div>
        </v-main>
    </v-app>
</template>
<script>
import AppBarAuth from "@/components/AppBarAuth";
import { FadeTransition } from "vue2-transitions";
import ContentFooter from "@/components/FooterAuth.vue";

export default {
    name: "auth-layout",
    components: {
        AppBarAuth,
        FadeTransition,
        ContentFooter
    },
    data() {
        return {};
    },
    methods: {}
};
</script>

<style lang="scss" scoped>
.auth-container {
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    &::before {
        // content: "";
        // position: absolute;
        // top: 0;
        // left: 0;
        // width: 150%;
        // height: 100vh;
        // background-color: white;
        // transform-origin: center center;
        // transform: translate(-25%, -50%) rotate(-10deg);
        // z-index: -1;
    }

    .authFooter {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
    }
}
</style>
