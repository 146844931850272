import AuthLayout from "@/views/Layout/AuthLayout";

export default {
    path: "/",
    component: AuthLayout,
    name: "Authentication",
    children: [
        {
            path: "/login",
            name: "login",
            component: () => import(/* webpackChunkName: "login" */ "@/views/Authentication/Login.vue"),
            meta: {
                mobileAllowed: true
            }
        },
        {
            path: "/forgot-password",
            name: "forgot-password",
            component: () => import(/* webpackChunkName: "forgot-password" */ "@/views/Authentication/ForgotPassword.vue"),
            meta: {
                mobileAllowed: true
            }
        },
        {
            path: "/reset-password",
            name: "reset-password",
            component: () => import(/* webpackChunkName: "reset-password" */ "@/views/Authentication/ResetPassword.vue"),
            meta: {
                mobileAllowed: true
            }
        },
        {
            path: "/set-password",
            name: "set-password",
            component: () => import(/* webpackChunkName: "reset-password" */ "@/views/Authentication/SetPassword.vue")
        },
        {
            path: "/error-not-authorized",
            name: "error-not-authorized",
            component: () => import(/* webpackChunkName: "reset-password" */ "@/views/Authentication/ErrorNotAuthorized.vue"),
            meta: {
                mobileAllowed: true
            }
        },
        {
            path: "/mobile-not-allowed",
            name: "mobile-not-allowed",
            component: () => import(/* webpackChunkName: "reset-password" */ "@/views/Authentication/MobileNotAllowed.vue"),
            meta: {
                mobileAllowed: true
            }
        },
        {
            path: "/create-account",
            name: "create-account",
            component: () => import(/* webpackChunkName: "create-account" */ "@/views/Onboarding/CreateAccount.vue")
        },
        {
            path: "/onboarding",
            name: "onboarding",
            component: () => import(/* webpackChunkName: "onboarding" */ "@/views/Onboarding/Onboarding.vue")
        },
        {
            path: "/setup-complete",
            name: "setup-complete",
            component: () => import(/* webpackChunkName: "members-list" */ "@/views/Onboarding/SetupComplete.vue")
        }
    ]
};
