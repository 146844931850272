import Vue from "vue";
import Router from "vue-router";
import store from '@/store'

import AuthRoutes from "./routes/auth.routes";
import AccountRoutes from "./routes/account.routes";
import AppRoutes from "./routes/app.routes";

import isMobile from "../plugins/isMobile";

Vue.use(Router);

const routes = [
  {
    path: '/',
    redirect: isMobile() ? '/my-forms' : '/dashboard/overview'
  },
  AuthRoutes,
  AccountRoutes,
  AppRoutes,
  {
    path: '*',
    name: 'error',
    component: () => import(/* webpackChunkName: "error-not-found" */ '@/views/Authentication/ErrorNotFound.vue')
  }
];

const router = new Router({
    mode: 'history',
    scrollBehavior() {
        return { x: 0, y: 0 }
    },
    routes
});

/**
 * Check if the route is permitted
 */
const permitted = (to) => {
    const authUser = store.getters['apiAuth/user']

    if (authUser) {
        if ('roles' in to.meta) {
            return to.meta.roles.includes(authUser.role)
        }
    }

    return true
}

router.beforeEach(async (to, from, next) => {
    let isAuthenticated = store.getters['apiAuth/isAuthenticated']

    const requiresAuth = to.matched.some(record => record.meta.requiresAuth)
    const mobileAllowed = to.matched.some(record => record.meta.mobileAllowed)

    if (isMobile() && !mobileAllowed) {
        next({ name: 'mobile-not-allowed' })
    }

    if (requiresAuth) { // All the app pages
        if (isAuthenticated && permitted(to)) {
            return next()
        } else if (isAuthenticated && !permitted(to)) {
            return next({ name: 'error-not-authorized' })
        }

        try {
            await store.dispatch('apiAuth/authenticate')
            return next()
        } catch (error) {
            return next({ name: 'login' })
        }
    } else { // All the authentication & onboarding pages
        const configs = await store.dispatch('configs/find', {})

        if (configs.total > 0) {
            if (to.name === 'create-account' || to.name === 'onboarding') {
                return next({ name: 'login' })
            }
        }

        if (isAuthenticated) {
            await store.dispatch('apiAuth/logout')
        }
    }

    return next()
})

export default router;
