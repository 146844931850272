import DashboardLayout from "../../views/Layout/DashboardLayout.vue";

export default {
    path: "/account",
    component: DashboardLayout,
    name: "Account",
    children: [
        {
            path: "/account",
            name: "Account",
            component: () => import(/* webpackChunkName: "account" */ "@/views/Account/Account.vue"),
            meta: {
                requiresAuth: true,
                mobileAllowed: true
            },
            children: [
                {
                    path: "/profile",
                    name: "Profile Information",
                    component: () => import(/* webpackChunkName: "profile" */ "@/views/Account/ProfileInformation.vue"),
                    meta: {
                        requiresAuth: true,
                        mobileAllowed: true
                    }
                },
                {
                    path: "/notifications",
                    name: "Notifications",
                    component: () => import(/* webpackChunkName: "notifications" */ "@/views/Account/Notifications.vue"),
                    meta: {
                        requiresAuth: true
                    }
                },
                {
                    path: "/users",
                    name: "Users",
                    component: () => import(/* webpackChunkName: "users" */ "@/views/Account/Users.vue"),
                    meta: {
                        requiresAuth: true,
                        roles: ['Admin', 'Manager', 'GlobalManager']
                    }
                },
                {
                    path: "/organisation",
                    name: "Organisation Information",
                    component: () => import(/* webpackChunkName: "organisation" */ "@/views/Account/OrganisationInformation.vue"),
                    meta: {
                        requiresAuth: true,
                        roles: ['Admin', 'Manager', 'GlobalManager']
                    }
                },
                {
                    path: "/licence",
                    name: "Licence Configuration",
                    component: () => import(/* webpackChunkName: "licence" */ "@/views/Account/LicenceConfiguration.vue"),
                    meta: {
                        requiresAuth: true,
                        roles: ['Admin']
                    }
                }
            ]
        }
    ]
};