import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
import DashboardPlugin from "./plugins/dashboard-plugin";
import VueFeather from "vue-feather";
import VueOtp2 from "vue-otp-2";
import moment from "moment";
import VueMoment from "vue-moment";
import store from "./store";
import VTooltip from "v-tooltip";
import { feathersConnectionString } from "../app.config";
import VueSignaturePad from 'vue-signature-pad';

Vue.config.productionTip = false;

// plugin setup
Vue.use(DashboardPlugin);
Vue.use(VueFeather);
Vue.use(VueOtp2);
Vue.use(VueMoment, { moment });
Vue.use(VTooltip);
Vue.use(VueSignaturePad);

import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, ArcElement } from "chart.js";
ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, ArcElement);

Vue.prototype.$feathersConnectionString = feathersConnectionString;

new Vue({
    router,
    vuetify,
    store,
    render: (h) => h(App)
}).$mount("#app");
