import { apiClient, makeServicePlugin, BaseModel } from "@/store/api";

class EmptyReportingModel extends BaseModel {
    constructor(data, options) {
        super(data, options);
    }

    // Required for $FeathersVuex plugin to work after production transpile.
    static modelName = "EmptyReporting";

    // Define default properties here
    static instanceDefaults() {
        return {
            context: ""
        };
    }
}

const servicePluginEmptyReporting = makeServicePlugin({
    service: apiClient.service('reporting/empty'),
    servicePath: 'reporting/empty',
    Model: EmptyReportingModel,
    namespace: 'reporting-empty'
});

export default servicePluginEmptyReporting;

