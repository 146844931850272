import { apiClient, makeServicePlugin, BaseModel } from "@/store/api";
// import sharp from "sharp";

class User extends BaseModel {
    constructor(data, options) {
        super(data, options);
    }

    // Required for $FeathersVuex plugin to work after production transpile.
    static modelName = "User";

    // Define default properties here
    static instanceDefaults() {
        return {
            id: null,
            first_name: null,
            last_name: null,
            email: null,
            isVerified: null,
            system: null,
            role: null,
            notifications: {
                emailNotifications: true
            },
            avatar: null
        };
    }
}
const servicePath = "users";
const servicePlugin = makeServicePlugin({
    Model: User,
    service: apiClient.service(servicePath),
    servicePath
});

// Setup the client-side Feathers hooks.
apiClient.service(servicePath).hooks({
    before: {
        all: [],
        find: [],
        get: [],
        create: [],
        update: [],
        patch: [],
        remove: []
    },
    after: {
        all: [],
        find: [],
        get: [],
        create: [],
        update: [],
        patch: [],
        remove: []
    },
    error: {
        all: [],
        find: [],
        get: [],
        create: [],
        update: [],
        patch: [],
        remove: []
    }
});

export default servicePlugin;
